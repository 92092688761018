import LngDetector from 'i18next-browser-languagedetector';
import backend from '@justgiving/i18next-dynamic-import-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .use(
    backend(
      lang =>
        import(
          /* webpackChunkName: "lang/[request]" */ `../lang/${lang}.json5`
        ),
      { loadDefault: true }
    )
  )
  .use(LngDetector)
  .init({
    whitelist: ['en', 'en-GB', 'en-US', 'en-AU'],
    fallbackLng: 'en',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'locale',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
