import React, { FunctionComponent } from 'react';
import styles from './Inspiration.module.scss';
import { InspirationCard } from './InspirationCard';
import { Experiments } from '../../constants/experiments';
import { useOptimizely } from '../../hooks/useOptimizely';
import config from '../../config';

export const Inspiration: FunctionComponent = () => {
  const optimizely = useOptimizely();
  const onClick = () => {
    optimizely && optimizely.track(Experiments.TrackingEvents.inspirationClick);
  };

  return (
    <div className={`${styles.tileBanner}`}>
      <div className="jg-grid-container">
        <section className="jg-grid-row">
          <h2 className={`jg-h1 ${styles.title}`}>Inspiration</h2>
          <ul className={styles.cardsWrapper}>
            <InspirationCard
              imgSrc={config.inspirationEventImage}
              href={config.eventsUrl}
              hrefText="Take part in an event"
              onClick={onClick}
            />
            <InspirationCard
              imgSrc={config.inspirationFRPIdeaImage}
              href={config.fundraisingIdeasUrl}
              hrefText="Find a fundraising idea"
              onClick={onClick}
            />
            <InspirationCard
              imgSrc={config.inspirationEmergencyImage}
              href={config.emergencyUrl}
              hrefText="Help in an emergency"
              onClick={onClick}
            />
          </ul>
        </section>
      </div>
    </div>
  );
};
