import {
  OptimizelyDecision,
  ReactSDKClient,
  enums,
} from '@optimizely/react-sdk';

export const setUpHeapOptimizely = (optimizely: ReactSDKClient) => {
  // @ts-ignore
  optimizely.notificationCenter.addNotificationListener<{
    type: string;
    decisionInfo: OptimizelyDecision;
    userId: string;
    // @ts-ignore
  }>(enums.NOTIFICATION_TYPES.DECISION, ({ type, decisionInfo }) => {
    // @ts-ignore
    if (type === 'flag' && decisionInfo.flagKey) {
      if (
        (window as any).Cookiebot &&
        (window as any).Cookiebot.consent &&
        (window as any).Cookiebot.consent.marketing
      ) {
        const eventProperties = {
          [decisionInfo.flagKey]: decisionInfo.variationKey,
        };

        const flagKey = [decisionInfo.flagKey];
        const experimentKey = '[Optimizely] ' + flagKey;
        const userProperties = {
          [experimentKey]: decisionInfo.variationKey,
        };

        if ((window as any).heap && (window as any).heap.addEventProperties) {
          (window as any).heap.addEventProperties(eventProperties);
        }

        if ((window as any).heap && (window as any).heap.addEventProperties) {
          (window as any).heap.addUserProperties(userProperties);
        }
      }
    }
  });
};
