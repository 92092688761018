import React, { FunctionComponent } from 'react';

import callOutImgSrc from './assets/Anniversary_Image.png';
import styles from './CallOut.module.scss';
import config from '../../config';

export const CallOut: FunctionComponent = () => {
  return (
    <section className={styles.callOut}>
      <div className={`container ${styles.container}`}>
        <div className={`jg-space-mtsm ${styles.callOutWrapper}`}>
          <div className={`jg-display-ib ${styles.content}`}>
            <h2 className={`jg-display-ib jg-space-ptmd ${styles.header}`}>
              We're celebrating 25 years of supporting millions of fundraisers
              and the causes they care about
            </h2>

            <div className={`jg-space-mtsm ${styles.buttonWrapper}`}>
              <a
                className={`jg-btn--ghost jg-space-mbm ${styles.button}`}
                href={config.jgAnniversaryUrl}
              >
                Find out more
              </a>
            </div>
          </div>

          <div className={`jg-display-ib ${styles.content} jg-flex-asc`}>
            <a href={config.jgAnniversaryUrl} tabIndex={-1} aria-hidden>
              <img
                src={callOutImgSrc}
                alt="25 years of Giving. Find out more"
                className="jg-space-psm callOut-img"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
