import { useContext } from 'react';
import { OptimizelyContext } from '@optimizely/react-sdk';
import { OptimizelyContextInterface } from '@optimizely/react-sdk/dist/Context';

export function useOptimizely() {
  const { optimizely } = useContext<OptimizelyContextInterface>(
    OptimizelyContext
  );
  return optimizely;
}
