import React, { FunctionComponent } from 'react';
import styles from './FundraisingJourney.module.scss';
import { FundraisingJourneyCard } from './FundraisingJourneyCard';
import config from '../../config';

export const FundraisingJourney: FunctionComponent = () => {
  return (
    <div className={`${styles.tileBanner}`}>
      <div className="jg-grid-container">
        <section className="jg-grid-row">
          <h2 className={`jg-h1 ${styles.title}`}>
            Start your fundraising journey
          </h2>
          <ul className={styles.cardsWrapper}>
            <FundraisingJourneyCard
              imgSrc={config.frJourneyStartImage}
              title="Start your fundraiser"
              description="Tell your story, set a target, add pictures and videos"
              count={1}
            />
            <FundraisingJourneyCard
              imgSrc={config.frJourneyShareWithFriendsImage}
              title="Share with friends"
              description="Send emails, share on social media, send text messages"
              count={2}
            />
            <FundraisingJourneyCard
              imgSrc={config.frJourneyManageDonatiosImage}
              title="Manage donations"
              description="Receive donations, thank donors, money goes to charity or your bank account"
              count={3}
            />
          </ul>
        </section>
      </div>
    </div>
  );
};
