export const Experiments = {
  TrackingEvents: {
    startFundraisingClick: 'Start fundraising homepage click',
    inspirationClick: 'Click homepage inspiration',
    searchSubmit: 'Search homepage click',
  },
  StartFundraisingJourney: {
    flagKey: 'consumerdarkslide_2797050_start_fundraising_journey',
  },
};
